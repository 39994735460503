<template>
    <Button v-bind="buttonProps" class="carnegie-button" :theme="theme">
        {{ label }}
        <template #icon v-if="type === 'video' || type === 'social'">
            <Icon v-if="type === 'video'" name="video" class="icon" />
            <Icon v-if="type === 'social'" :name="socialNetworks[0].network" class="icon" />
        </template>
    </Button>
</template>

<script setup>
const props = defineProps({
    appearance: {
        type: String,
    },
    file: {
        type: Object,
    },
    label: {
        type: String,
    },
    newTab: {
        type: Boolean,
    },
    reference: {
        type: Object,
    },
    type: {
        type: String,
    },
    url: {
        type: String,
    },
    socialNetworks: {
        type: Array,
    },
    theme: {
        ...themes,
    },
});

const buttonProps = {
    variant: props.appearance?.replace('Button', '') || 'primary',
    newTab: props.newTab || props.type === 'video' || props.type === 'download',
    url:
        props.type === 'internal'
            ? props.reference?.path
            : props.type === 'external' || props.type === 'video'
            ? props.url
            : props.type === 'download'
            ? props.file?.url
            : props.type === 'social'
            ? getSocialUrl(props.socialNetworks[0]?.handle, props.socialNetworks[0]?.network)
            : null,
    label: props.label,
    iconPosition: props.type === 'video' || props.type === 'social' ? 'left' : 'right',
};
</script>

<style lang="scss" scoped>
.carnegie-button {
    .icon {
        width: 1.6rem;
    }
}
</style>
